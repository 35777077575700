import React, { FC, SVGProps } from 'react';

export const SalmonLogo: FC<SVGProps<SVGSVGElement>> = ({ width = 90, height = 22 }) => {
    return (
        <svg width="91" height="22" viewBox="0 0 91 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27 14.9786C28.2617 16.1838 30.0318 16.9748 32.5741 16.9748C35.9449 16.9748 37.8092 15.2988 37.8092 12.7754C37.8092 9.93186 34.9468 9.32925 32.8754 8.89613C31.4818 8.6325 30.7098 8.40652 30.7098 7.74743C30.7098 7.20132 31.1241 6.7682 32.1974 6.7682C33.3085 6.7682 34.6832 7.20132 35.7377 8.06756L37.5079 5.75131C36.1708 4.6026 34.4195 4 32.4046 4C29.1844 4 27.3955 5.82663 27.3955 7.93574C27.3955 10.9111 30.2955 11.4384 32.3669 11.8526C33.7039 12.1351 34.5137 12.4176 34.5137 13.1332C34.5137 13.7358 33.8358 14.2066 32.7247 14.2066C31.0111 14.2066 29.6175 13.4533 28.7136 12.5494L27 14.9786Z" fill="#999999"/>
            <path d="M44.4273 16.7488H47.3273V11.0617C47.3273 8.23704 45.2747 7.42729 43.0903 7.42729C41.6968 7.42729 40.1338 7.84158 38.9662 8.83964L40.002 10.6663C40.7364 10.0448 41.678 9.70588 42.5819 9.70588C43.7494 9.70588 44.4273 10.2332 44.4273 11.0241V12.1728C43.8812 11.4949 42.8078 11.1182 41.6026 11.1182C40.2091 11.1182 38.4766 11.7773 38.4766 13.9806C38.4766 16.0332 40.2091 16.9748 41.6026 16.9748C42.7702 16.9748 43.8624 16.5228 44.4273 15.8449V16.7488ZM44.4273 14.4137C44.126 14.8468 43.4481 15.0728 42.8078 15.0728C42.0357 15.0728 41.3578 14.7338 41.3578 14.0182C41.3578 13.3027 42.0357 12.9637 42.8078 12.9637C43.4481 12.9637 44.126 13.1897 44.4273 13.604V14.4137Z" fill="#999999"/>
            <path d="M49.244 16.7488H52.144V4.18831H49.244V16.7488Z" fill="#999999"/>
            <path d="M65.526 16.7488H68.4072V10.1578C68.4072 8.29353 67.3526 7.42729 65.6767 7.42729C64.2455 7.42729 63.0591 8.25587 62.5318 9.04678C62.1552 7.9734 61.2513 7.42729 59.9519 7.42729C58.5208 7.42729 57.3532 8.23704 56.9578 8.76432V7.65327H54.0578V16.7488H56.9578V10.8734C57.2591 10.4968 57.8052 9.98835 58.5773 9.98835C59.4623 9.98835 59.7825 10.5156 59.7825 11.2124V16.7488H62.6825V10.8734C62.965 10.4968 63.5299 9.98835 64.3208 9.98835C65.187 9.98835 65.526 10.5156 65.526 11.2124V16.7488Z" fill="#999999"/>
            <path d="M74.6968 16.9748C77.8228 16.9748 79.6683 14.7527 79.6683 12.1916C79.6683 9.63055 77.8228 7.42729 74.6968 7.42729C71.6085 7.42729 69.7442 9.63055 69.7442 12.1916C69.7442 14.7527 71.6085 16.9748 74.6968 16.9748ZM74.6968 14.4137C73.4351 14.4137 72.7384 13.4156 72.7384 12.1916C72.7384 10.9864 73.4351 9.98835 74.6968 9.98835C75.9774 9.98835 76.693 10.9864 76.693 12.1916C76.693 13.4156 75.9774 14.4137 74.6968 14.4137Z" fill="#999999"/>
            <path d="M87.1664 16.7488H90.0664V10.3085C90.0664 8.6325 89.1248 7.42729 87.0534 7.42729C85.5281 7.42729 84.4547 8.12405 83.9086 8.76432V7.65327H81.0086V16.7488H83.9086V10.8734C84.2475 10.4591 84.8125 9.98835 85.6787 9.98835C86.5826 9.98835 87.1664 10.3838 87.1664 11.4949V16.7488Z" fill="#999999"/>
            <path d="M16.712 0H5.23867C2.34543 0 0 2.3507 0 5.25044V16.7496C0 19.6493 2.34543 22 5.23867 22H16.712C19.6052 22 21.9507 19.6493 21.9507 16.7496V5.25044C21.9507 2.3507 19.6052 0 16.712 0Z" fill="url(#paint0_linear_1645_5493)"/>
            <path d="M16.712 0H5.23867C2.34543 0 0 2.3507 0 5.25044V16.7496C0 19.6493 2.34543 22 5.23867 22H16.712C19.6052 22 21.9507 19.6493 21.9507 16.7496V5.25044C21.9507 2.3507 19.6052 0 16.712 0Z" fill="url(#paint1_linear_1645_5493)"/>
            <path d="M14.1129 7.86707C13.5265 7.51524 12.9083 7.2649 12.2728 7.12378L11.5997 5.62657C11.5196 5.37913 11.2872 5.21191 11.0249 5.21191H10.9979C10.7356 5.21191 10.5031 5.3801 10.4231 5.62657L9.74994 7.12282C9.11343 7.2649 8.49332 7.51621 7.90601 7.86804C6.73908 10.1327 6.42276 12.3403 6.58092 14.5171C7.26468 15.1791 8.78072 15.9978 9.5937 16.3216C10.0701 16.5111 10.2157 16.6068 10.6931 16.686C11.1175 16.8059 11.2457 16.7595 11.5707 16.7257C12.0712 16.5923 12.2796 16.4569 12.4821 16.3477C13.3423 15.9021 14.7581 15.1801 15.4418 14.518C15.627 11.9933 15.1245 9.80601 14.1129 7.86611V7.86707ZM9.77308 12.4486C9.40565 12.4486 9.10186 12.0107 9.10186 11.4714C9.10186 10.9321 9.39504 10.4903 9.77115 10.4903C10.1473 10.4903 10.4491 10.9321 10.4424 11.4714C10.4356 12.0107 10.1463 12.4486 9.77212 12.4486H9.77308ZM12.2477 12.4486C11.8793 12.4486 11.5775 12.0107 11.5775 11.4714C11.5775 10.9321 11.8707 10.4903 12.2477 10.4903C12.6248 10.4903 12.9238 10.9321 12.917 11.4714C12.9103 12.0107 12.6219 12.4486 12.2477 12.4486Z" fill="white"/>
            <path d="M17.1525 13.4173L16.2903 10.7003C16.1842 10.3678 15.8766 10.1416 15.5275 10.1416C15.4233 10.1416 15.3221 10.1619 15.2285 10.1996C15.622 11.5093 15.7802 12.9224 15.6567 14.4622H16.3887C16.9307 14.4622 17.3155 13.9344 17.1515 13.4173H17.1525Z" fill="white"/>
            <path d="M4.83798 13.4686L5.70015 10.7515C5.80623 10.419 6.11388 10.1929 6.46299 10.1929C6.56714 10.1929 6.66841 10.2132 6.76195 10.2509C6.36848 11.5606 6.21032 12.9737 6.33376 14.5134H5.60178C5.05979 14.5134 4.67499 13.9857 4.83894 13.4686H4.83798Z" fill="white"/>
            <path d="M16.712 0H5.23867C2.34543 0 0 2.3507 0 5.25044V16.7496C0 19.6493 2.34543 22 5.23867 22H16.712C19.6052 22 21.9507 19.6493 21.9507 16.7496V5.25044C21.9507 2.3507 19.6052 0 16.712 0Z" fill="url(#paint2_linear_1645_5493)"/>
            <path d="M14.8594 6.96953C14.1171 6.52409 13.3344 6.20714 12.5298 6.02848L11.6775 4.1329C11.5762 3.81962 11.2819 3.60791 10.9498 3.60791H10.9156C10.5835 3.60791 10.2892 3.82084 10.1879 4.1329L9.33564 6.02725C8.52979 6.20714 7.74468 6.52531 7.00109 6.97076C5.52368 9.83799 5.1232 12.633 5.32344 15.3889C6.18913 16.2272 8.10854 17.2637 9.13784 17.6736C9.74102 17.9135 9.92539 18.0346 10.5298 18.135C11.067 18.2867 11.2294 18.228 11.6409 18.1851C12.2746 18.0163 12.5383 17.8449 12.7947 17.7067C13.8839 17.1425 15.6763 16.2284 16.542 15.3901C16.7764 12.1937 16.1403 9.42436 14.8594 6.96831V6.96953ZM9.36495 12.7701C8.89975 12.7701 8.51513 12.2157 8.51513 11.5329C8.51513 10.85 8.88632 10.2908 9.36251 10.2908C9.8387 10.2908 10.2209 10.85 10.2123 11.5329C10.2038 12.2157 9.83747 12.7701 9.36373 12.7701H9.36495ZM12.498 12.7701C12.0316 12.7701 11.6494 12.2157 11.6494 11.5329C11.6494 10.85 12.0206 10.2908 12.498 10.2908C12.9754 10.2908 13.354 10.85 13.3454 11.5329C13.3369 12.2157 12.9718 12.7701 12.498 12.7701Z" fill="#FCFCFC"/>
            <path d="M18.7054 13.9964L17.6139 10.5564C17.4795 10.1355 17.09 9.84912 16.648 9.84912C16.5162 9.84912 16.388 9.87482 16.2695 9.92255C16.7677 11.5807 16.9679 13.3698 16.8117 15.3193H17.7384C18.4246 15.3193 18.9118 14.6511 18.7042 13.9964H18.7054Z" fill="#FCFCFC"/>
            <path d="M3.11545 14.0613L4.20702 10.6214C4.34133 10.2004 4.73083 9.91406 5.17283 9.91406C5.3047 9.91406 5.43291 9.93976 5.55134 9.98749C5.05318 11.6457 4.85293 13.4348 5.00922 15.3842H4.08248C3.39628 15.3842 2.9091 14.716 3.11667 14.0613H3.11545Z" fill="#FCFCFC"/>
            <defs>
                <linearGradient id="paint0_linear_1645_5493" x1="10.9753" y1="0" x2="10.9753" y2="22" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#E07C82"/>
                    <stop offset="1" stop-color="#E07C82" stop-opacity="0"/>
                </linearGradient>
                <linearGradient id="paint1_linear_1645_5493" x1="10.9753" y1="0" x2="10.9753" y2="22" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#E07C82"/>
                    <stop offset="1" stop-color="#E07C82" stop-opacity="0"/>
                </linearGradient>
                <linearGradient id="paint2_linear_1645_5493" x1="10.9753" y1="0" x2="10.9753" y2="22" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF8170"/>
                    <stop offset="1" stop-color="#FF5C45"/>
                </linearGradient>
            </defs>
        </svg>



    );
};
