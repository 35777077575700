import React, { FC, SVGProps } from 'react';

export const SolanaPayLogoBySalmon: FC<SVGProps<SVGSVGElement>> = ({ width = 60, height = 22 }) => {
    return (
        <svg width="240" height="88" viewBox="0 0 240 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M180.485 28.538L171.682 9.30322H164.375L176.951 35.2565L176.725 36.038C176.41 37.0767 175.745 37.9738 174.843 38.5761C173.942 39.1784 172.86 39.4486 171.782 39.3403C170.52 39.3286 169.282 38.9947 168.186 38.3698L167.016 43.9286C168.704 44.6257 170.51 44.9894 172.336 44.9998C177.366 44.9998 180.409 43.1469 182.925 37.6892L196.243 9.30322H189.187L180.485 28.538Z" fill="black"/>
            <path d="M118.269 0.139648H97.166V35.496H104.083V22.5004H118.269C125.978 22.5004 131.046 18.6057 131.046 11.3201C131.046 4.03451 125.978 0.139648 118.269 0.139648ZM117.891 16.3115H104.058V6.22774H117.891C121.89 6.22774 124.179 8.05543 124.179 11.2697C124.179 14.4839 121.89 16.3115 117.891 16.3115Z" fill="black"/>
            <path d="M159.964 28.7902V18.1393C159.964 11.8369 155.449 8.57227 147.702 8.57227C141.414 8.57227 135.906 11.5092 134.359 16.0216L140.044 18.0385C140.886 15.7822 143.816 14.1184 147.463 14.1184C151.789 14.1184 153.613 15.8831 153.613 18.0385V18.7318L143.326 19.8662C137.402 20.4963 133.504 23.1559 133.504 27.9081C133.504 33.1137 137.968 35.9121 143.892 35.9121C147.71 36.0313 151.428 34.6777 154.28 32.1307C155.311 34.6515 156.367 36.3405 163.397 35.4457V30.1768C160.58 30.8574 159.964 30.1768 159.964 28.7902ZM153.676 25.4121C153.676 28.8408 148.884 30.6559 144.872 30.6559C141.817 30.6559 139.98 29.6726 139.98 27.719C139.98 25.7652 141.49 25.0594 144.407 24.7188L153.701 23.6349L153.676 25.4121Z" fill="black"/>
            <path d="M83.9551 26.7764C84.0037 26.9219 84.0037 27.0794 83.9551 27.2249C83.9269 27.3701 83.8579 27.5042 83.7557 27.6112L77.1643 34.5264C77.019 34.6758 76.8453 34.7944 76.6534 34.8753C76.461 34.9597 76.2531 35.0022 76.0431 34.9999H44.7805C44.6358 35.0012 44.4934 34.9625 44.3694 34.8879C44.2453 34.8035 44.1463 34.6871 44.0828 34.5515C44.0389 34.4136 44.0389 34.2656 44.0828 34.1277C44.1086 33.9842 44.1732 33.8508 44.2697 33.7415L50.8735 26.8262C51.0188 26.677 51.1925 26.5584 51.3844 26.4775C51.5764 26.3921 51.7848 26.3494 51.995 26.3529H83.2075C83.3581 26.3499 83.5062 26.3933 83.6311 26.4775C83.7713 26.5353 83.8864 26.6413 83.9551 26.7764ZM77.1769 13.6437C77.0284 13.4983 76.8554 13.3802 76.666 13.2949C76.4716 13.2165 76.2649 13.1743 76.0554 13.1703H44.7805C44.6339 13.1724 44.4909 13.2156 44.3676 13.2948C44.2442 13.3741 44.1456 13.4863 44.0828 13.6188C44.0397 13.7567 44.0397 13.9045 44.0828 14.0424C44.1038 14.1874 44.169 14.3223 44.2697 14.4287L50.8735 21.3563C51.022 21.5018 51.1949 21.6199 51.3844 21.7053C51.5783 21.7847 51.7854 21.8269 51.995 21.8299H83.2075C83.3581 21.8327 83.5062 21.7892 83.6311 21.7053C83.757 21.6319 83.8536 21.5176 83.9053 21.3812C83.9692 21.2488 83.9904 21.0996 83.9657 20.9546C83.9413 20.8094 83.8723 20.6755 83.7683 20.5715L77.1769 13.6437ZM44.3694 8.5352C44.4934 8.60978 44.6358 8.64857 44.7805 8.64734H76.0554C76.2654 8.6495 76.4736 8.60704 76.666 8.52273C76.8579 8.44177 77.0316 8.32314 77.1769 8.17386L83.7683 1.25862C83.8703 1.15163 83.9395 1.01755 83.9677 0.872372C84.0108 0.734452 84.0108 0.586658 83.9677 0.448738C83.9161 0.312525 83.8194 0.19817 83.6935 0.124789C83.5684 0.0408307 83.4205 -0.00268116 83.2697 0.000182764H51.9451C51.7349 -0.00315848 51.5266 0.0393485 51.3346 0.124789C51.1427 0.205757 50.9689 0.324383 50.8237 0.473659L44.2323 7.41379C44.1278 7.51903 44.0582 7.65389 44.0329 7.80005C43.989 7.93787 43.989 8.08589 44.0329 8.22368C44.1138 8.35688 44.2303 8.46478 44.3694 8.5352Z" fill="url(#paint0_linear_1664_5803)"/>
            <path d="M107.658 74.6066C109.226 76.1038 111.424 77.0862 114.582 77.0862C118.77 77.0862 121.086 75.0043 121.086 71.8697C121.086 68.3374 117.53 67.5889 114.957 67.0508C113.226 66.7233 112.267 66.4426 112.267 65.6239C112.267 64.9455 112.781 64.4075 114.115 64.4075C115.495 64.4075 117.202 64.9455 118.512 66.0216L120.711 63.1443C119.05 61.7173 116.875 60.9688 114.372 60.9688C110.372 60.9688 108.149 63.2378 108.149 65.8578C108.149 69.5538 111.752 70.2088 114.325 70.7235C115.986 71.0744 116.992 71.4252 116.992 72.3142C116.992 73.0627 116.15 73.6475 114.77 73.6475C112.641 73.6475 110.91 72.7118 109.787 71.589L107.658 74.6066Z" fill="black"/>
            <path d="M129.307 76.8055H132.909V69.741C132.909 66.2321 130.359 65.2262 127.646 65.2262C125.915 65.2262 123.973 65.7408 122.523 66.9806L123.81 69.2497C124.722 68.4778 125.891 68.0567 127.014 68.0567C128.465 68.0567 129.307 68.7117 129.307 69.6942V71.1211C128.628 70.279 127.295 69.8111 125.798 69.8111C124.067 69.8111 121.915 70.6299 121.915 73.3668C121.915 75.9166 124.067 77.0862 125.798 77.0862C127.248 77.0862 128.605 76.5248 129.307 75.6827V76.8055ZM129.307 73.9049C128.932 74.4429 128.09 74.7236 127.295 74.7236C126.336 74.7236 125.494 74.3025 125.494 73.4136C125.494 72.5247 126.336 72.1036 127.295 72.1036C128.09 72.1036 128.932 72.3843 129.307 72.899V73.9049Z" fill="black"/>
            <path d="M135.29 76.8055H138.893V61.2027H135.29V76.8055Z" fill="black"/>
            <path d="M155.516 76.8055H159.095V68.6181C159.095 66.3023 157.785 65.2262 155.703 65.2262C153.925 65.2262 152.452 66.2555 151.797 67.238C151.329 65.9046 150.206 65.2262 148.592 65.2262C146.814 65.2262 145.364 66.2321 144.872 66.8871V65.5069H141.27V76.8055H144.872V69.507C145.247 69.0392 145.925 68.4076 146.884 68.4076C147.984 68.4076 148.381 69.0626 148.381 69.9281V76.8055H151.984V69.507C152.335 69.0392 153.036 68.4076 154.019 68.4076C155.095 68.4076 155.516 69.0626 155.516 69.9281V76.8055Z" fill="black"/>
            <path d="M166.908 77.0862C170.791 77.0862 173.084 74.3259 173.084 71.1445C173.084 67.9631 170.791 65.2262 166.908 65.2262C163.072 65.2262 160.756 67.9631 160.756 71.1445C160.756 74.3259 163.072 77.0862 166.908 77.0862ZM166.908 73.9049C165.341 73.9049 164.475 72.665 164.475 71.1445C164.475 69.6474 165.341 68.4076 166.908 68.4076C168.499 68.4076 169.388 69.6474 169.388 71.1445C169.388 72.665 168.499 73.9049 166.908 73.9049Z" fill="black"/>
            <path d="M182.398 76.8055H186.001V68.8053C186.001 66.7233 184.831 65.2262 182.258 65.2262C180.363 65.2262 179.03 66.0917 178.351 66.8871V65.5069H174.749V76.8055H178.351V69.507C178.772 68.9924 179.474 68.4076 180.55 68.4076C181.673 68.4076 182.398 68.8988 182.398 70.279V76.8055Z" fill="black"/>
            <path d="M94.8791 56H80.6267C77.0327 56 74.1191 58.9201 74.1191 62.5222V76.8066C74.1191 80.4087 77.0327 83.3288 80.6267 83.3288H94.8791C98.4732 83.3288 101.387 80.4087 101.387 76.8066V62.5222C101.387 58.9201 98.4732 56 94.8791 56Z" fill="url(#paint1_linear_1664_5803)"/>
            <path d="M94.8791 56H80.6267C77.0327 56 74.1191 58.9201 74.1191 62.5222V76.8066C74.1191 80.4087 77.0327 83.3288 80.6267 83.3288H94.8791C98.4732 83.3288 101.387 80.4087 101.387 76.8066V62.5222C101.387 58.9201 98.4732 56 94.8791 56Z" fill="url(#paint2_linear_1664_5803)"/>
            <path d="M91.6496 65.7724C90.9212 65.3354 90.1533 65.0244 89.3638 64.8491L88.5276 62.9892C88.4282 62.6818 88.1395 62.4741 87.8136 62.4741H87.7801C87.4542 62.4741 87.1655 62.683 87.0661 62.9892L86.2299 64.8479C85.4392 65.0244 84.6689 65.3366 83.9393 65.7736C82.4897 68.5868 82.0968 71.3292 82.2932 74.0331C83.1426 74.8556 85.0259 75.8726 86.0358 76.2748C86.6276 76.5102 86.8085 76.629 87.4015 76.7275C87.9286 76.8764 88.0879 76.8187 88.4917 76.7767C89.1134 76.611 89.3722 76.4429 89.6238 76.3072C90.6924 75.7537 92.451 74.8568 93.3004 74.0343C93.5304 70.8981 92.9063 68.181 91.6496 65.7712V65.7724ZM86.2586 71.4637C85.8022 71.4637 85.4248 70.9198 85.4248 70.2498C85.4248 69.5798 85.789 69.0311 86.2562 69.0311C86.7234 69.0311 87.0984 69.5798 87.09 70.2498C87.0816 70.9198 86.7222 71.4637 86.2574 71.4637H86.2586ZM89.3327 71.4637C88.875 71.4637 88.5001 70.9198 88.5001 70.2498C88.5001 69.5798 88.8642 69.0311 89.3327 69.0311C89.8011 69.0311 90.1725 69.5798 90.1641 70.2498C90.1557 70.9198 89.7975 71.4637 89.3327 71.4637Z" fill="white"/>
            <path d="M95.4252 72.6668L94.3541 69.2917C94.2224 68.8786 93.8402 68.5977 93.4065 68.5977C93.2772 68.5977 93.1514 68.6229 93.0352 68.6697C93.5239 70.2966 93.7204 72.052 93.5671 73.9647H94.4763C95.1496 73.9647 95.6276 73.3092 95.424 72.6668H95.4252Z" fill="white"/>
            <path d="M80.1282 72.7308L81.1992 69.3556C81.331 68.9426 81.7132 68.6616 82.1469 68.6616C82.2762 68.6616 82.402 68.6868 82.5182 68.7337C82.0295 70.3606 81.833 72.116 81.9863 74.0287H81.077C80.4038 74.0287 79.9258 73.3731 80.1294 72.7308H80.1282Z" fill="white"/>
            <path d="M94.8791 56H80.6267C77.0327 56 74.1191 58.9201 74.1191 62.5222V76.8066C74.1191 80.4087 77.0327 83.3288 80.6267 83.3288H94.8791C98.4732 83.3288 101.387 80.4087 101.387 76.8066V62.5222C101.387 58.9201 98.4732 56 94.8791 56Z" fill="url(#paint3_linear_1664_5803)"/>
            <path d="M92.5774 64.6578C91.6552 64.1045 90.683 63.7108 89.6834 63.4888L88.6247 61.1341C88.4988 60.7449 88.1333 60.4819 87.7207 60.4819H87.6783C87.2657 60.4819 86.9002 60.7464 86.7743 61.1341L85.7156 63.4873C84.7145 63.7107 83.7393 64.106 82.8156 64.6593C80.9803 68.2211 80.4828 71.6931 80.7316 75.1165C81.8069 76.1578 84.1913 77.4454 85.4699 77.9546C86.2192 78.2526 86.4482 78.4031 87.199 78.5277C87.8664 78.7162 88.0681 78.6433 88.5792 78.5901C89.3664 78.3803 89.694 78.1675 90.0126 77.9957C91.3655 77.2949 93.5921 76.1593 94.6675 75.118C94.9587 71.1474 94.1684 67.7072 92.5774 64.6563V64.6578ZM85.752 71.8634C85.1741 71.8634 84.6963 71.1747 84.6963 70.3265C84.6963 69.4782 85.1574 68.7835 85.749 68.7835C86.3405 68.7835 86.8152 69.4782 86.8046 70.3265C86.794 71.1747 86.339 71.8634 85.7505 71.8634H85.752ZM89.644 71.8634C89.0646 71.8634 88.5898 71.1747 88.5898 70.3265C88.5898 69.4782 89.0509 68.7835 89.644 68.7835C90.237 68.7835 90.7072 69.4782 90.6966 70.3265C90.686 71.1747 90.2325 71.8634 89.644 71.8634Z" fill="#FCFCFC"/>
            <path d="M97.354 73.3867L95.9981 69.1135C95.8312 68.5906 95.3474 68.2349 94.7983 68.2349C94.6345 68.2349 94.4752 68.2668 94.3281 68.3261C94.947 70.3859 95.1957 72.6084 95.0016 75.03H96.1528C97.0052 75.03 97.6104 74.2 97.3525 73.3867H97.354Z" fill="#FCFCFC"/>
            <path d="M77.9882 73.4672L79.3442 69.1941C79.511 68.6711 79.9948 68.3154 80.5439 68.3154C80.7077 68.3154 80.867 68.3474 81.0141 68.4066C80.3953 70.4665 80.1465 72.6889 80.3407 75.1105H79.1895C78.337 75.1105 77.7319 74.2805 77.9897 73.4672H77.9882Z" fill="#FCFCFC"/>
            <path d="M53.84 71.1241V69.5191H56.66V66.9241H58.295V69.5191H61.1V71.1241H58.295V73.7191H56.66V71.1241H53.84Z" fill="black"/>
            <defs>
            <linearGradient id="paint0_linear_1664_5803" x1="47.3765" y1="35.8342" x2="79.4001" y2="-1.06483" gradientUnits="userSpaceOnUse">
            <stop offset="0.08" stop-color="#9945FF"/>
            <stop offset="0.3" stop-color="#8752F3"/>
            <stop offset="0.5" stop-color="#5497D5"/>
            <stop offset="0.6" stop-color="#43B4CA"/>
            <stop offset="0.72" stop-color="#28E0B9"/>
            <stop offset="0.97" stop-color="#19FB9B"/>
            </linearGradient>
            <linearGradient id="paint1_linear_1664_5803" x1="87.7529" y1="56" x2="87.7529" y2="83.3288" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E07C82"/>
            <stop offset="1" stop-color="#E07C82" stop-opacity="0"/>
            </linearGradient>
            <linearGradient id="paint2_linear_1664_5803" x1="87.7529" y1="56" x2="87.7529" y2="83.3288" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E07C82"/>
            <stop offset="1" stop-color="#E07C82" stop-opacity="0"/>
            </linearGradient>
            <linearGradient id="paint3_linear_1664_5803" x1="87.7529" y1="56" x2="87.7529" y2="83.3288" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FF8170"/>
            <stop offset="1" stop-color="#FF5C45"/>
            </linearGradient>
            </defs>
        </svg>

    );
};
